import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AbilityBuilder, createMongoAbility } from "@casl/ability";
import { ability } from "@/core/helpers/ability";
import { User, UserAuthInfo, Permissions } from "@/core/types";
import { ActionsPermissions } from "@/core/enums";
import { cleanQuerySearch } from "@/core/helpers/filters";

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  get savedUserId() {
    return window.localStorage.getItem("user_login_id");
  }

  @Mutation
  [Mutations.SET_USER_ID](id) {
    window.localStorage.setItem("user_login_id", id);
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    console.log("Mutations.SET_AUTH >", user);
    this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
    if (user.token) {
      JwtService.saveToken(user.token);
      ApiService.setHeader();
    }
  }

  @Mutation
  [Mutations.SET_USER](user) {
    console.log("Mutations.SET_USER >", user);
    this.user = user;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    ability.update([]);
    JwtService.destroyToken();
    ApiService.setHeader();
    cleanQuerySearch({ property: "ordenes" });
  }

  @Mutation
  [Mutations.SET_RULES](permissions: Permissions[]) {
    const { can, rules } = new AbilityBuilder(createMongoAbility);
    permissions.forEach((rule) => {
      if (rule.agregar) {
        can(ActionsPermissions.ADD, rule.vista);
      }
      if (rule.listar) {
        can(ActionsPermissions.LIST, rule.vista);
      }
      if (rule.editar) {
        can(ActionsPermissions.EDIT, rule.vista);
      }
      if (rule.eliminar) {
        can(ActionsPermissions.DELETE, rule.vista);
      }
      if (rule.ver) {
        can(ActionsPermissions.VIEW, rule.vista);
      }
      if (rule.buscar) {
        can(ActionsPermissions.SEARCH, rule.vista);
      }
    });
    ability.update(rules);
  }

  @Action
  [Actions.UPDATE_RULES](perfilId) {
    return ApiService.query("profile/getProfilePermissions", {
      params: {
        perfilId,
      },
    })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RULES, data.lstPermissions);
      })
      .catch(({ response }) => {
        if (response && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, {
            er: ["No hemos podido conectarnos con el servidor."],
          });
        }
      });
  }

  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post("Identity", credentials)
      .then(({ data }) => {
        if (data.id && data.token) {
          this.context.commit(Mutations.SET_AUTH, data);
          this.context.commit(Mutations.SET_USER_ID, data.id);
          this.context.dispatch(Actions.UPDATE_RULES, data.perfilId);
          this.context.dispatch(Actions.UPDATE_USER, data.id);
        } else {
          this.context.commit(Mutations.SET_ERROR, {
            er: [data.message],
            changePassword: data.changePassword,
            token: data.token,
          });
        }
      })
      .catch(({ response }) => {
        if (response && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, {
            er: ["No hemos podido conectarnos con el servidor."],
          });
        }
      });
  }

  @Action
  [Actions.UPDATE_USER](userId: string) {
    return ApiService.get("user/getInformationById", "?userId=" + userId)
      .then(({ data }) => {
        if (!data.error) {
          this.context.commit(Mutations.SET_AUTH, { ...data, id: userId });
          this.context.commit(Mutations.SET_USER_ID, userId);
          this.context.dispatch(Actions.UPDATE_RULES, data.perfilId);
        } else {
          this.context.commit(Mutations.SET_ERROR, { er: [data.message] });
        }
      })
      .catch(({ response }) => {
        if (response && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, {
            er: ["No hemos podido conectarnos con el servidor."],
          });
        }
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
    this.context.commit(Mutations.SET_USER_ID, "");
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.get("identity/forgotPassword", "?email=" + payload.email)
      .then(({ data }) => {
        if (data.error) {
          this.context.commit(Mutations.SET_ERROR, {
            er: [data.message],
          });
        } else {
          this.context.commit(Mutations.SET_ERROR, {});
        }
      })
      .catch(({ response }) => {
        if (response && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, {
            er: ["No hemos podido conectarnos con el servidor."],
          });
        }
      });
  }

  @Action
  [Actions.UPDATE_PASSWORD](values) {
    return ApiService.post("Identity/updatePassword", values)
      .then(({ data }) => {
        if (!data.error) {
          this.context.commit(Mutations.SET_ERROR, {});
        } else {
          this.context.commit(Mutations.SET_ERROR, { er: [data.message] });
        }
      })
      .catch(({ response }) => {
        if (response && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, {
            er: ["No hemos podido conectarnos con el servidor."],
          });
        }
      });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("user/isAlive")
        .then(() => {
          if (!this.user.id) {
            this.context.dispatch(Actions.UPDATE_USER, this.savedUserId);
          }
        })
        .catch(() => {
          this.context.commit(Mutations.PURGE_AUTH);
          window.location.reload();
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
