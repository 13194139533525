import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";

interface Page {
  querySearch: Record<string, unknown> | null;
  maxUploadSize: number;
}

@Module
export default class PageModule extends VuexModule implements Page {
  querySearch = {};
  maxUploadSize = 0;

  get getQuery() {
    return this.querySearch;
  }

  get getMaxUploadSize() {
    return this.maxUploadSize;
  }

  @Mutation
  [Mutations.SET_QUERY_SEARCH](payload) {
    this.querySearch = payload;
  }
  @Mutation
  [Mutations.CLEAR_QUERY_SEARCH]() {
    this.querySearch = {};
  }

  @Mutation
  [Mutations.SET_MAX_UPLOAD_SIZE](payload: number) {
    this.maxUploadSize = payload;
  }

  @Action
  [Actions.GET_INFORMATION_MAX_UPLOAD_SIZE]() {
    ApiService.query("master/getAll", {
      params: {
        pageNumber: 1,
        tableName: "MaxTamanoArchivo",
      },
    })
      .then(({ data }) => {
        if (!data.error) {
          const [{ codigo }] = data.lstMasters;
          this.context.commit(Mutations.SET_MAX_UPLOAD_SIZE, Number(codigo));
          console.log(this.getMaxUploadSize);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
