import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboards"],
          requiresAuth: true,
        },
      },
      {
        path: "/security/users",
        name: "Usuarios",
        component: () => import("@/views/sodexo/security/Users.vue"),
        meta: {
          pageTitle: "Usuarios",
          breadcrumbs: ["Usuarios"],
          requiresAuth: true,
          permissions: ["usuarios"],
        },
      },
      {
        path: "/security/roles",
        name: "Roles",
        component: () => import("@/views/sodexo/security/Roles.vue"),
        meta: {
          pageTitle: "Roles",
          breadcrumbs: ["Roles"],
          requiresAuth: true,
          permissions: ["roles"],
        },
      },
      {
        path: "/distribution-center",
        name: "Centros de distribución",
        component: () =>
          import("@/views/sodexo/suppliers/CenterDistributions.vue"),
        meta: {
          pageTitle: "Centros de distribución",
          breadcrumbs: ["Centros de distribución"],
          requiresAuth: true,
          permissions: ["distribution-center"],
        },
      },
      {
        path: "/work-orders",
        name: "Órdenes de trabajo",
        component: () => import("@/views/temis/WorkOrders.vue"),
        meta: {
          pageTitle: "Órdenes de trabajo",
          breadcrumbs: ["Órdenes de trabajo"],
          requiresAuth: true,
          permissions: ["distribution-center"],
        },
      },
      {
        path: "/repository",
        name: "Repositorio",
        component: () => import("@/views/sodexo/Repository.vue"),
        meta: {
          pageTitle: "Repositorio de archivos",
          breadcrumbs: ["Repositorio"],
          requiresAuth: true,
          permissions: ["repository"],
        },
      },
      {
        path: "/security/permissions",
        name: "Permisos",
        component: () => import("@/views/sodexo/security/Permissions.vue"),
        meta: {
          pageTitle: "Permisos",
          breadcrumbs: ["Permisos"],
          requiresAuth: true,
          permissions: ["permisos"],
        },
      },
      {
        path: "/security/suppliers",
        name: "Proveedores",
        component: () => import("@/views/sodexo/security/Suppliers.vue"),
        meta: {
          pageTitle: "Proveedores",
          breadcrumbs: ["Proveedores"],
          requiresAuth: true,
          permissions: ["proveedores"],
        },
      },
      {
        path: "/security/masters",
        name: "Masters",
        component: () => import("@/views/sodexo/security/Masters.vue"),
        meta: {
          pageTitle: "Masters",
          breadcrumbs: ["Masters"],
          requiresAuth: true,
          permissions: ["masters"],
        },
      },
      {
        path: "/suppliers/orders",
        name: "ordenes",
        component: () => import("@/views/sodexo/suppliers/Orders.vue"),
        meta: {
          pageTitle: "Órdenes",
          breadcrumbs: ["Órdenes"],
          requiresAuth: true,
          permissions: ["ordenes"],
        },
      },
      {
        path: "/suppliers/orders/compliances/:order_id",
        name: "conformidades-recepciones",
        component: () =>
          import("@/views/sodexo/suppliers/OrdersConfirmations.vue"),
        meta: {
          pageTitle: "Conformidades/Recepciones",
          breadcrumbs: ["Órdenes - Conformidades/Recepciones"],
          requiresAuth: true,
          permissions: ["ordenes-conf-recep"],
        },
      },
      {
        path: "/suppliers/orders/compliances/:order_id/:dp_action/:dp_id",
        name: "documentos-de-pago",
        component: () =>
          import("@/views/sodexo/suppliers/OrdersConfirmations.vue"),
        meta: {
          pageTitle: "Conformidades/Recepciones",
          breadcrumbs: ["Órdenes - Conformidades/Recepciones"],
          requiresAuth: true,
          permissions: ["ordenes-conf-recep"],
        },
      },
      {
        path: "/suppliers/orders/payment-docs/:order_id",
        name: "order-payment-docs",
        component: () =>
          import("@/views/sodexo/suppliers/OrdersPaymentDocs.vue"),
        meta: {
          pageTitle: "Documentos de Pago",
          breadcrumbs: ["Órdenes - Doc. Pagos"],
          requiresAuth: true,
          permissions: ["ordenes-doc-pagos"],
        },
      },
      {
        path: "/suppliers/claims",
        name: "claims",
        component: () => import("@/views/sodexo/suppliers/Claims.vue"),
        meta: {
          pageTitle: "Reclamos",
          breadcrumbs: ["Reclamos"],
          requiresAuth: true,
          permissions: ["reclamos"],
        },
      },
      {
        path: "/suppliers/claims/:actions/:type",
        name: "new-claim",
        component: () => import("@/views/sodexo/suppliers/SelectOrder.vue"),
        meta: {
          pageTitle: "Nuevo reclamo",
          breadcrumbs: ["Reclamos"],
          requiresAuth: true,
          permissions: ["reclamos-dp-compras"],
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/recover-password",
        name: "recover-password",
        component: () =>
          import(
            "@/views/crafted/authentication/basic-flow/RecoverPassword.vue"
          ),
      },
    ],
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 403 route, when user doesn't have permissions
        path: "/403",
        name: "403",
        component: () => import("@/views/crafted/authentication/Error403.vue"),
      },
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  store.dispatch(Actions.VERIFY_AUTH);

  if (to.meta.requiresAuth) {
    if (store.getters.isUserAuthenticated) {
      next();
    } else {
      next({ name: "sign-in", query: { redirect: to.fullPath } });
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

router.onError((e) => {
  console.log("e => ", e);
});

export default router;
