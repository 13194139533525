enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  UPDATE_PASSWORD = "updatePassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",
  UPDATE_RULES = "updateRoles",
  GET_INFORMATION_MAX_UPLOAD_SIZE = "getInformationMaxUploadSize",

  //workorders
  GET_WORKS_ORDERS_ALL = "getWorkOrdersAll",
  GET_WORKS_ORDERS_ALL_USERS = "getWorkOrdersAllUsers",
  GET_WO_LIST_PROPOSAL_BY_PROPUESTA_ID = "getWoListProposalByPropuestaId",
  GET_WO_LIST_REPORT_BY_PROPUESTA_ID = "getWoListReportByPropuestaId",
  GET_WO_LIST_FINAL_DOCUMENTS_BY_PROPUESTA_ID = "getWoListFinalDocumentsByPropuestaId",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG_PROPERTY = "setLayoutConfigProperty",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",
  SET_RULES = "setRoles",
  SET_USER_ID = "setUserId",
  SET_QUERY_SEARCH = "setQuerySearch",
  CLEAR_QUERY_SEARCH = "clearQuerySearch",
  SET_MAX_UPLOAD_SIZE = "setMaxUploadSize",
  SET_WORK_ORDERS = "setWorkOrders",
  SET_PAGINATION = "setPagination",
  SET_RESPONSIBLE_USER = "setResponsibleUser",
  SET_WO_LIST_PROPOSAL_BY_PROPUESTA_ID = "setWoListProposalByPropuestaId",
  SET_WO_LIST_REPORT_BY_PROPUESTA_ID = "setWoListReportByPropuestaId",
  SET_WO_LIST_FINAL_DOCUMENTS_BY_PROPUESTA_ID = "setWoListFinalDocumentsByPropuestaId",
}

export { Actions, Mutations };
