interface FilterProps {
  property: string;
  value?: Record<string, string> | string;
}

export const getQuerySearch = ({ property }: FilterProps) => {
  return localStorage.getItem(property)
    ? JSON.parse(localStorage.getItem(property))
    : null;
};

export const setQuerySearch = ({ property, value }: FilterProps): void => {
  localStorage.setItem(property, JSON.stringify(value));
};

export const cleanQuerySearch = ({ property }: FilterProps): void => {
  localStorage.removeItem(property);
};
