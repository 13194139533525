import numeral from "numeral";
import moment from "moment";

export const filters = {
  formatNumber(value: number) {
    return numeral(value).format("0,0.00");
  },
  formatDatetime(value) {
    if (value) {
      return moment(String(value)).format("DD/MM/YYYY hh:mmA");
    } else {
      return value;
    }
  },
  formatDateAndTime(value) {
    if (value) {
      return moment(String(value)).format("DD/MM/YYYY hh:mm:ssA");
    } else {
      return value;
    }
  },
  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD/MM/YYYY");
    } else {
      return value;
    }
  },
  formatDateTemis(value) {
    if (value) {
      //return moment(String(value)).format("DD/MM/YYYY");
      const d = String(value).split(" ");
      return d[0];
    } else {
      return value;
    }
  },
  formatDatetimeTemis(value) {
    return value;
    if (value) {
      const d = String(value).split(" ");
    } else {
      return value;
    }
  },
};
