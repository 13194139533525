import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import axios from "axios";

type WorkOrderPagination = {
  page: number;
  pageCount: number;
  total: number;
};

type WorkOrder = {
  CentroCosto: string;
  Detalle: string;
  Estado: string;
  Familia: string;
  FechaCierre: string;
  FechaRegistro: string;
  NumeroOrdenTrabajo: string;
  OrdenTrabajoProveedorId: string;
  CantidadPropuestas: string;
  Prioridad: string;
  Subestado: string;
  UsuarioResponsable: string;
};

type responsibleUsers = {
  codigo: string;
  usuarioResponsableId: string;
};

type PropuestaFile = {
  nombreArchivo: string;
  rutaArchivo: string;
};

interface Propuestas {
  detallePropuesta: PropuestaFile[];
  fechaRegistro: string;
  ordenTrabajoPropuestaId: string;
  propuestaId: string;
}

interface Documentaciones {
  nombreArchivo: string;
  rutaArchivo: string;
}

@Module
export default class WorkOrderModule extends VuexModule {
  workOrders: WorkOrder[] = [];
  isLoading: true;
  woListProposalByPropuestaId: Propuestas[] = [];
  woListReportByPropuestaId: Documentaciones[] = [];
  woListFinalDocumentsByPropuestaId: Documentaciones[] = [];
  responsibleUsers: responsibleUsers[] = [];
  errors = {};
  pagination = {
    page: 1,
    pageCount: 0,
    total: 0,
  };

  get getOrders() {
    return this.workOrders;
  }

  get getIsLoading() {
    return this.isLoading;
  }

  get getResponsibleUsers() {
    return this.responsibleUsers;
  }

  get getWorkOrderErrors() {
    return this.errors;
  }

  get getPagination() {
    return this.pagination;
  }

  get getWoListProposals() {
    return this.woListProposalByPropuestaId;
  }

  get getWoReportDocumentations() {
    return this.woListReportByPropuestaId;
  }

  get getWoFinalDocuments() {
    return this.woListFinalDocumentsByPropuestaId;
  }

  @Mutation
  [Mutations.SET_WORK_ORDERS](payload) {
    this.workOrders = payload;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_RESPONSIBLE_USER](payload) {
    this.responsibleUsers = payload;
  }

  @Mutation
  [Mutations.SET_PAGINATION](payload: WorkOrderPagination) {
    this.pagination = payload;
  }

  @Mutation
  [Mutations.SET_WO_LIST_PROPOSAL_BY_PROPUESTA_ID](payload) {
    this.woListProposalByPropuestaId = payload;
  }

  @Mutation
  [Mutations.SET_WO_LIST_FINAL_DOCUMENTS_BY_PROPUESTA_ID](payload) {
    this.woListFinalDocumentsByPropuestaId = payload;
  }

  @Mutation
  [Mutations.SET_WO_LIST_REPORT_BY_PROPUESTA_ID](payload) {
    this.woListReportByPropuestaId = payload;
  }

  @Action
  [Actions.GET_WORKS_ORDERS_ALL](payload: {
    page: number;
    proveedorId: string;
    usuarioResponsableId: string;
  }) {
    axios
      .get(process.env.VUE_APP_API_TEMIS + "/getAllWorkOrders", {
        params: {
          numeroPagina: payload.page,
          proveedorId: payload.proveedorId,
          usuarioResponsableId: payload.usuarioResponsableId,
        }
      })
      .then(({ data: { ListarOrdenesTrabajoResult } }) => {
        const { data, error } = ListarOrdenesTrabajoResult;

        console.log(ListarOrdenesTrabajoResult);
        if (!error) {
          const { ordenesTrabajo, total, totalPaginas } = data;
          // console.log(totalPaginas, total);
          this.context.commit(Mutations.SET_WORK_ORDERS, ordenesTrabajo);
          this.context.commit(Mutations.SET_PAGINATION, {
            page: payload.page,
            pageCount: totalPaginas,
            total: total,
          });
        }
      })
      .catch(({ response }) => {
        console.log(response);
      });
  }

  @Action
  [Actions.GET_WO_LIST_PROPOSAL_BY_PROPUESTA_ID](payload: {
    ordenTrabajoProveedorId: string;
  }) {
    axios
      .get(process.env.VUE_APP_API_TEMIS + "/getAllProposals", {
        params: {
          ordenTrabajoProveedorId: payload.ordenTrabajoProveedorId,
        },
      })
      .then(({ data }) => {
        const { ListarPropuestaResult } = data;
        const { data: listarPropuestaData, error } = ListarPropuestaResult;
        if (!error) {
          this.context.commit(
            Mutations.SET_WO_LIST_PROPOSAL_BY_PROPUESTA_ID,
            listarPropuestaData.propuestas
          );
        }
      })
      .catch(({ response }) => {
        console.log(response);
      });
  }

  @Action
  [Actions.GET_WO_LIST_FINAL_DOCUMENTS_BY_PROPUESTA_ID](payload: {
    ordenTrabajoProveedorId: string;
  }) {
    axios
      .get(process.env.VUE_APP_API_TEMIS + "/getAllFinalDocumentation", {
        params: {
          ordenTrabajoProveedorId: payload.ordenTrabajoProveedorId,
        },
      })
      .then(({ data }) => {
        const { ListarDocumentacionFinalResult } = data;
        const { data: listarPropuestaData, error } =
          ListarDocumentacionFinalResult;
        console.log("listarPropuestaData => ", listarPropuestaData);
        if (!error) {
          this.context.commit(
            Mutations.SET_WO_LIST_FINAL_DOCUMENTS_BY_PROPUESTA_ID,
            listarPropuestaData.documentaciones
          );
        }
      })
      .catch(({ response }) => {
        console.log("catch", response);
      });
  }

  @Action
  [Actions.GET_WO_LIST_REPORT_BY_PROPUESTA_ID](payload: {
    ordenTrabajoProveedorId: string;
  }) {
    axios
      .get(process.env.VUE_APP_API_TEMIS + "/getAllInitialDocumentation", {
        params: {
          ordenTrabajoProveedorId: payload.ordenTrabajoProveedorId,
        },
      })
      .then(({ data }) => {
        const { ListarDocumentacionInicialResult } = data;
        const { data: listarPropuestaData, error } =
          ListarDocumentacionInicialResult;
        if (!error) {
          this.context.commit(
            Mutations.SET_WO_LIST_REPORT_BY_PROPUESTA_ID,
            listarPropuestaData.documentaciones
          );
        }
      })
      .catch(({ response }) => {
        console.log("catch", response);
      });
  }

  @Action
  [Actions.GET_WORKS_ORDERS_ALL_USERS]() {
    axios
      .get(process.env.VUE_APP_API_TEMIS + "/getAllUsers")
      .then(({ data: { ListarUsuarioResponsableResult } }) => {
        const { data, error } = ListarUsuarioResponsableResult;

        console.log(ListarUsuarioResponsableResult);
        if (!error) {
          const { usuariosResponsable } = data;
          console.log(usuariosResponsable);
          this.context.commit(
            Mutations.SET_RESPONSIBLE_USER,
            usuariosResponsable
          );
        }
      })
      .catch(({ response }) => {
        console.log(response);
      });
  }
}
