enum ActionsPermissions {
  ADD = "agregar",
  EDIT = "editar",
  DELETE = "eliminar",
  LIST = "listar",
  VIEW = "ver",
  SEARCH = "buscar",
}

export { ActionsPermissions };
